<template>
  <div>
    <h3>▶에너지 소비량</h3>
    <b-form @submit.prevent>
      <b-row>
        <b-col md="12">
          <b-form-group
            label="소비연도"
            label-for="useYyCd"
          >
            <v-select
              id="useYyCd"
              v-model="mainSelected.useYyCd"
              label="cdNm"
              taggable
              push-tags
              :options="commCodeData.USE_YY_CD"
              @input="onClickCommProc($event)"
            />
          </b-form-group>
          <b-form-group
            label="에너지원"
            label-for="engyKindCd"
          >
            <v-select
              id="engyKindCd"
              v-model="mainSelected.engyKindCd"
              label="cdNm"
              placeholder="전체"
              :options="commCodeData.ENGY_KIND_CD"
              @input="onClickCommProc($event)"
            />
          </b-form-group>
          <b-form-group
            label="사용용도"
            label-for="clsfKindCd"
          >
            <v-select
              id="clsfKindCd"
              v-model="mainSelected.clsfKindCd"
              label="cdNm"
              placeholder="전체"
              :options="commCodeData.CLSF_KIND_CD"
              @input="onClickCommProc($event)"
            />
          </b-form-group>
          <b-form-group
            label="건물소비량"
            label-for=".engyAllQtyCd"
          >
            <v-select
              id="engyAllQtyCd"
              v-model="mainSelected.engyAllQtyCd"
              label="cdNm"
              placeholder="선택"
              :options="commCodeData.ENGY_ALL_QTY_CD"
              @input="onClickCommProc($event)"
            />
          </b-form-group>
          <b-form-group
            label="에너지사용량"
            label-for="engyUse"
          >
            <v-select
              id="engyUse"
              v-model="mainSelected.engyUse"
              label="cdNm"
              placeholder="선택"
              :options="mainSelected.engyUseCd"
              @input="onChageEngyUse($event)"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>

        <b-col
          cols="12"
          style="position: relative;"
        >
          <div class="d-flex justify-content-start ">
            <img
              src="/gis/map/gis/images/common/legend.jpg"
              alt="색상 범례"
            >
          </div>
        </b-col>
      </b-row>
    </b-form>
  </div>
  
</template>

<script>
import { BRow, BCol, BFormGroup, BForm } from "bootstrap-vue";
import vSelect from "vue-select";
import { mapGetters } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    vSelect,

  },
  data() {
    return {
      // 건물소비량 디폴트값

      engyUseYn: this.engyUseChange,
      // 주용도 검색 가공 데이터
      commonEnergy: {},
      purpsArr: [],
    }
  },
  computed: {
    ...mapGetters({
      commCodeData: "main/getCommCodeData",
      mainSelected: "main/getSelectedCodeData",
      engyUseChange: "main/getEngyUseChange", // / true: 에너지 사용량 건물, false: 시군구,읍면동

    }),
  },
  created() {
    // 공통코드 불러오기
    this.$store.dispatch("main/FETCH_COMM_CODE_DATA");
  },
  methods: {
    onChageEngyUse(event) {
      /*
        시군구, 읍면동는 주용도검색 비활성
        건물일 경우 주용도검색 활성
      */
      if (event) {
        /* if (this.mainSelected.engyUse.cdId === "build") {
           this.engyUseYn = false;
         } else {
           this.engyUseYn = true; // 주용도 검색창 가리기

           this.$store.dispatch("main/FETCH_SELECT_PURPS_DATA", null);
         } */

        this.$store.dispatch('main/FETCH_ENERGY_USE_CHANGE', this.engyUseYn);
        this.onClickCommProc(event);
      }
    },

    onClickCommProc(event) {
      // 소비연도
      if (event) {
        this.commonEnergy.useYyCd = this.mainSelected.useYyCd.cdId;
        this.commonEnergy.engyKindCd = this.mainSelected.engyKindCd.cdId;
        this.commonEnergy.clsfKindCd = this.mainSelected.clsfKindCd.cdId;
        this.commonEnergy.engyAllQtyCd = this.mainSelected.engyAllQtyCd.cdId;
        this.commonEnergy.engyUse = this.mainSelected.engyUse.cdId;

        if (this.mainSelected.MAIN_PURPS_CD) {
          this.mainSelected.MAIN_PURPS_CD.forEach(item => {
            const temp = {};
            temp.prupsCd = item.cdId;

            if (!this.purpsArr.includes(item.cdId)) {
              this.purpsArr.push(item.cdId);
            }
            this.commonEnergy.prupsCd = this.purpsArr
          });
        }

        // console.log(JSON.stringify(this.commonEnergy));
        gis.search.changeEnergyFilter(this.commonEnergy);
      }
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.colors-list {
  li {
    padding: 0.4rem;

    svg {
      margin-right: 0.25rem;
    }
  }
}

.vs__clear {
  display: none;
}
</style>
